import React, {useEffect} from "react";
import {motion} from "framer-motion";
import "./Skills.scss";
import {client, urlFor} from "../../client";
import {AppWrapper} from "../../wrapper";

const Skills = () => {

    const [skills, setSkills] = React.useState([]);

    useEffect(() => {
        const query = '*[_type == "skills"]';

        client.fetch(query)
            .then(data => {
                    setSkills(data);
                }
            )
    }, []);


    return (
        <>
            <div className="app__skills ">
            <h2 className="head-text">I am proficient in the following <span>Technologies</span></h2>
            <div className="app__skills-container ">
                <motion.div className="app__skills-list">
                    {skills.map((skill) => (
                        <motion.div
                            key={skill._id}
                            whileInView={{opacity: [0, 1]}}
                            transition={{duration: .5}}
                            className="app__skills-item app__flex"

                            >
                            <div className="app__flex" >
                                <img src={urlFor(skill.icon)} alt={skill.name} />
                                <p className="p-text">{skill.name}</p>
                            </div>


                        </motion.div>
                    ))}
                </motion.div>
            </div>
            </div>
        </>
    );
}


export default AppWrapper( Skills, "skills");