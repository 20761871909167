import React, {useEffect, useState} from 'react';

import './Projects.scss';
import {AppWrapper} from "../../wrapper";
import {client, urlFor} from "../../client";

const Projects = () => {

        const [projects, setProjects] = useState([]);

        useEffect(() => {
            const query = '*[_type == "projects"]';

            client.fetch(query)
                .then(data => {
                        setProjects(data);
                    }
                )
        }, []);

        return (
            <>
                <h2 id={"projects"} className="head-text">Check out my creative <span>Projects</span></h2>
                <div className="app__projects">
                    {projects.map((project) => (
                        <div className="app__projects-item"
                             key={project.title}>

                            {project.image && (
                                <img className="app__projects-image"
                                     src={urlFor(project.image) }
                                     alt={project.title}
                                />

                            )}
                                {project.media!==undefined && project.media.map((media) => (

                                    <div className="app__projects-media"
                                         key={media}
                                    >
                                        {media.includes("youtube") &&(
                                            <div className={"app__projects-video-wrapper"}>
                                        <iframe className={"app__projects-video"}
                                                src={`https://www.youtube.com/embed/${media.split("v=")[1]}?controls=1`}
                                                title={project.title}
                                                width={720}
                                                height={480}
                                                allowFullScreen=""
                                        ></iframe>
                                            </div>
                                        )
                                        }
                                    </div>
                                ))}
                            <div className="app__projects-text">
                            <h3 className="app__projects-title">{project.title}</h3>
                            <p className="app__projects-description p-text">{project.description}</p>
                            </div>
                            <div className="app__projects-links">
                                {project.source && (
                                    <a className="app__projects-link"
                                        href={project.source}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Source
                                    </a>
                                )}

                                {project.github && (
                                    <a className="app__projects-link-2"
                                        href={project.github}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Code
                                    </a>
                                )}
                            </div>

                        </div>
                    ))}

                </div>
            </>
        );
    }
;

export default AppWrapper(Projects, "projects", "app__darkbg");