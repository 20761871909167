import {motion} from "framer-motion";
import "./Navbar.scss"
const Sidenav = ({toggle, setToggle}) => {



    const props = {
        open: {
            x: 0,
            transition: {
                duration: .5,
                easing: "easeInOut"
            }
        },

        closed: {
            x: 700,
            transition: {
                duration: 0.5
            }
        },
        exit: {
            x: 700,
            transition: {
                duration: 0.5
            }
        }
    }
    return (
        <motion.div
            className="background"
            // enter screen from the right on active state
            initial="closed"
            animate="open"
            exit="exit"
            variants={props}

        >


            <ul>
                {['Home', 'Projects','Games', 'Skills',  'Contact'].map((item, index) => (
                    <li  key={item}>
                        <a href={`#${item.toLowerCase()}`} onClick={() => setToggle(false)}>
                            {item}
                        </a>
                    </li>
                ))}
            </ul>
        </motion.div>
    )
}

export default Sidenav


