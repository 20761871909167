import React, {useState} from "react";
import {client} from "../../client";
import "./Footer.scss";
import {AppWrapper, MotionWrap} from "../../wrapper";

const Footer = () => {
    const [formData, setFormData] = useState({ username: "", email: "", message: "" });
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [loading, setLoading] = useState(false);
    const [errorState, setErrorState] = useState({ usernameError: false, emailError: false, messageError: false });
    const { username, email, message } = formData;
    const {usernameError, emailError, messageError} = errorState;

    const handleChangeInput = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
        setErrorState({ ...errorState, [`${name}Error`]: false })
    };

    const handleSubmit = () => {
        setLoading(true);

        if (!validateForm()) {
            setLoading(false);
            return;
        }
        const contact = {
            _type: 'contact',
            name: formData.username,
            email: formData.email,
            message: formData.message,
        };


        client.create(contact)
            .then(() => {
                setLoading(false);
                setIsFormSubmitted(true);
            })
            .catch((err) => console.log(err));
    };

    const validateForm = () => {
        errorState.usernameError = false;
        errorState.emailError = false;
        errorState.messageError = false;
        let isValid = true;
        // validate email
        if (!email.includes('@')|| !email.includes('.') || email.length < 5) {
            errorState.emailError = true;
            isValid = false;
        }
        if (username.length < 1) {
            errorState.usernameError = true;
            isValid = false;
        }


        // validate message
        if (message.length < 1) {
            errorState.messageError = true;
            isValid = false;
        }

        setErrorState({...errorState});
        return isValid;
    }

    return (
        <>
            <h2 id="footer" className="head-text"><span>Contact </span>Me</h2>


            {!isFormSubmitted ? (
                <div className="app__footer-form app__flex">
                    <div className={"app__flex" }>
                        <span className="error-text">{errorState.usernameError? "invalid name": ""}</span>
                        <input className={  errorState.usernameError? 'p-text error':"p-text "} type="text" placeholder="Your Name" name="username" value={username} onChange={handleChangeInput} />
                    </div>
                    <div className="app__flex">
                        <span className="error-text">{errorState.emailError? "invalid email": ""}</span>
                        <input className={  errorState.emailError? 'p-text error':"p-text "} type="email" placeholder="Your Email" name="email" value={email} onChange={handleChangeInput} />
                    </div>
                    <div>
                        <span className="error-text">{errorState.messageError? "please enter a message": ""}</span>

                        <textarea
                            className={  errorState.messageError? 'p-text error':"p-text "}                placeholder="Your Message"
                value={message}
                name="message"
                onChange={handleChangeInput}
            />
                    </div>
                    <button type="button" className="p-text" onClick={handleSubmit}>{!loading ? 'Send Message' : 'Sending...'}</button>
                </div>
            ) : (
                <div>
                    <h3 className="head-text">
                        Thank you for getting in touch!
                    </h3>
                </div>
            )}

            <div className="app__footer-copyright"
           >
                <p className="p-text">© 2023 Maxwex</p>
            </div>

        </>
    );
}

export default AppWrapper(
    Footer,'contact', 'app__footer app__darkbg');