import logo from './logo.svg';
import './App.scss';
import {Navbar} from "./components";
//import all components from container.js
import { About, Games, Projects, Header, Footer, Skills} from "./container";


function App() {
  return (
    <div className="app">
        <Navbar />
        <Header />
        <Projects />
        <Games />

        <Skills />


        <Footer />
    </div>
  );
}

export default App;
