import React from "react";
import Typewriter from "typewriter-effect";
import "./Header.scss";
import {motion} from "framer-motion";
import {helloWorld, images} from "../../constants";
import {AppWrapper} from "../../wrapper";

const Header = () => {




    const options = {
        strings: helloWorld,
        delay: 200,
        autoStart: true,
        loop: true,
        pauseFor: 2000,
        cursor: "_",

    }
    return (
        <div id="home" className="app__header app__flex">
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1}}
                className="app__header-info">
                <div className="app__header-badge">
                    <div className="badge-cmp app__flex">
                        <div className="greeting">
                           <Typewriter
                                 options={options}

                           />
                        </div>

                        <h1
                            className="my-name"

                            // create after element

                        >Maxwex

                        </h1>
                        <h3 className="my-title">Student, Developer, Game Designer</h3>


                    </div>
                </div>
            </motion.div>

            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                transition={{duration: 1}} className="app__header-img">

                <img src={images.profile} alt="profile"/>
            </motion.div>


        </div>


    );
}

export default AppWrapper(Header, "home");