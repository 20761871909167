import React, {useEffect} from "react";

import "./Games.scss";
import {client, urlFor} from "../../client";
import {AppWrapper} from "../../wrapper";

const Games = () => {

    const [games, setGames] = React.useState([]);

    useEffect(() => {
        const query = '*[_type == "games"]';

        client.fetch(query)
            .then(data => {
                    setGames(data);
                }
            )
    }, []);

    return (
        <>
            <h2 id={"games"} className="head-text">Try playing my fun <span>Games</span></h2>
            <div className="app__games">
                {games.map((game) => (

                    <div className="app__games-item"
                         key={game.title}
                         onClick={
                             () => {
                                 window.open(game.gameLink, "_blank");
                             }
                         }>

                        <img src={urlFor(game.gameImage)} alt={game.title}/>

                        <div className="app__games-info">
                            <h3 className="app__games-title">{game.title}</h3>
                            <p className="app__games-desc p-text">{game.description}</p>
                            <div className="app__games-links">

                            </div>
                        </div>


                    </div>
                ))

                }

            </div>

        </>

    );


}


export default AppWrapper(Games, "games", "app__graybg");