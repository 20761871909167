import React, {useState} from 'react';

//import icons from react icons for toggle button
import {HiMenuAlt4, HiX} from 'react-icons/hi';
import {AnimatePresence, motion, useCycle} from "framer-motion";

import {images} from '../../constants';
import './Navbar.scss';
import {ToggleButton} from "./ToggleButton";
import Sidenav from "./Sidenav";

const Navbar = () => {

    const [toggle, setToggle] = useCycle(false, true)



    return (
        <motion.nav
            animate={toggle ? "open" : "closed"}
            className="app__navbar">
            <div className="app__navbar-logo">
                <img src={images.logo} alt="logo"/>
            </div>
            <ul className="app__navbar-links">
                {['Home', 'Projects','Games', 'Skills',  'Contact'].map((item, index) => (
                    <li className="app__flex p-text" key={`link-${item}`}>
                        <div/>
                        <a href={`#${item.toLowerCase()}`}>{item}</a>

                    </li>
                ))}
            </ul>


            <div className="app__navbar-menu">
                <ToggleButton
                    toggle={toggle} setToggle={setToggle}/>
                <AnimatePresence
                    initial={false}
                    mode={"sync"}
                >
                    {toggle && (
                        <Sidenav key={"Sidenav"} toggle={toggle} setToggle={setToggle}
                        />

                    )

                    }</AnimatePresence>
            </div>
        </motion.nav>

    );
};

export default Navbar;